<template>
    <span v-for="(item, index) in items.slice(0, limit)" 
        @mousedown="handleMouseDown"
        class="field-selection-item field-selection-item-chip"
        :class="{ 'is-focused': focusIndex === index }"
        :title="getItemText(item)"
        @click="$emit('itemClick', index)">
        <span class="field-selection-item-text">{{ getItemText(item) }}</span>
        <button v-if="handleItemDelete" @click="handleItemDelete!(item)" type="button" class="field-selection-item-remove-cta">
            <Icon symbol="close" size="s"></Icon>
        </button>
    </span>
    <span v-if="items.slice(limit).length > 0"
        @mousedown="handleMouseDown"
        class="field-selection-item caption fw-semibold"
        :class="{ 'is-focused': focusIndex > 0 }">
        <span>+ {{ items.slice(limit).length }} more</span>
    </span>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import { Icon } from '@/modules/core/components';

defineEmits<{
    (e: 'itemClick', realIndex: number): void
}>();

/**
 * This component renders the field selection items in a truncated fashion. Given
 * [x, x, x] will product "(getItemText(x)) +2 more"
 */
const props = defineProps({
    limit: {
        type: Number,
        default: 1,
    },
    items: {
        type: Array as PropType<any[]|any>,
        required: true,
    },
    focusIndex: {
        type: Number,
        required: true,
    },
    handleMouseDown: {
        type: Function as PropType<(event: Event) => void>,
        required: true,
    },
    getItemText: {
        type: Function as PropType<(object: any) => string>,
        required: true
    },
    handleItemDelete: {
        type: Function as PropType<(item: object) => void>
    },
})

</script>